import React from 'react';

import CategoryListCell from '../../molecules/category/CategoryListCell';

import * as styles from './index.module.css';

const CategoryList = ({ categories }) => {
  return (
    <div>
      <ul className={styles['list']}>
        {categories.map(category => (
          <li className={styles['listItem']} key={category.id}>
            <CategoryListCell category={category} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
