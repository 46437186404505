import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';
import CategoryList from '../../organisms/CategoryList';

import Breadcrumb from '../../molecules/common/Breadcrumb';
import MainHeading from '../../molecules/common/MainHeading';
import ContentArea from '../../molecules/common/ContentArea';

import * as styles from './index.module.css';

const CategoryListTemplate = ({ pageContext }) => {
  const { pagePath, categories } = pageContext;

  const breadcrumbItems = [
    {
      to: '/',
      label: 'ホーム',
    },
    {
      to: '/category',
      label: 'カテゴリー一覧',
    },
  ];

  return (
    <PublicRoot>
      <SEO path={pagePath} title="カテゴリー一覧" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <nav className={styles['nav']}>
              <ContainerDefault>
                <Breadcrumb items={breadcrumbItems} />
              </ContainerDefault>
            </nav>

            <section>
              <ContainerDefault>
                <div className={styles['headingWrapper']}>
                  <InlineBlockWrapper>
                    <MainHeading heading="カテゴリー 一覧" />
                  </InlineBlockWrapper>
                </div>

                <ContentArea>
                  <CategoryList categories={categories} />
                </ContentArea>
              </ContainerDefault>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default CategoryListTemplate;
