import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const CategoryListCell = ({ category }) => {
  const [linkStyle, updateLinkStyle] = useState({});

  const onLinkMouseEnter = useCallback(() => {
    updateLinkStyle({
      backgroundColor: '#E5E5E5',
    });
  }, [updateLinkStyle]);

  const onLinkMouseLeave = useCallback(() => {
    updateLinkStyle({});
  }, [updateLinkStyle]);

  return (
    <div>
      <Link
        className={styles['link']}
        to={`/category/${category.slug}`}
        style={linkStyle}
        onMouseEnter={onLinkMouseEnter}
        onMouseLeave={onLinkMouseLeave}
      >
        <span className={styles['label']}>{category.name}</span>
      </Link>
    </div>
  );
};

export default CategoryListCell;
